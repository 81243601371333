import _  from 'lodash'
import styled from 'styled-components';
import { devices, colors, borderRadius, fontStyles } from '../../globalStyles'
import Dropdown from "./Dropdown"
import Checkbox from "./Checkbox"

const MultiSelectComponent = ({selectedItems, onUpdate, options, title}) => {
    
    const onSelect = (isChecked, option) => {
        const newItems = updateSelectedItems(isChecked, option);
        onUpdate(newItems);
    };

    const updateSelectedItems = (isChecked, option) => {
        if (isChecked === true ) {
            const selectedItemsCopy = selectedItems;
            selectedItemsCopy.push(option);
            return selectedItemsCopy;
        } else {
            return _.filter(selectedItems, (item) => { return item.value !== option.value})
        }
    }

    const DropdonwContent = ({options}) => {
        return (
            <DropdonwContentWrapper>
                {options.map(option => (
                    <Checkbox 
                        key={option.value} 
                        checked={_.some(selectedItems, option)} 
                        onCheck={(i, option) => onSelect(i, option)} 
                        labelText={option.label}
                        item={option} 
                    />
                ))}
            </DropdonwContentWrapper>
        );
    };

    const getHeaderText = (input) => {
        return (
            <HeaderText>
                {input} 
                {selectedItems && selectedItems.length > 0 
                    && <Span>{selectedItems.length}</Span>}
            </HeaderText>
        );
    };

    return (
        <Wrapper>
            <Dropdown 
                header={getHeaderText(title)} 
                renderContent={<DropdonwContent options={options} />} />
        </Wrapper>
    );
};

export default MultiSelectComponent;

const Wrapper = styled.div`
    min-width: 160px;
    margin-right: 25px;
    @media ${devices.mobileL} {
        width: 100%;
        margin: 10px 0;
    }
`;

const DropdonwContentWrapper = styled.div`
    border: 3px solid ${colors.blue};
    padding: 0.5em 10px;
    border-radius: ${borderRadius};
    margin-top: 5px;
    background-color: white;
    min-width: 135px;
    font-family: ${fontStyles.body};
`;

const HeaderText = styled.div`
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: ${colors.blue};
    margin-right: 15px;
    font-family: ${fontStyles.headline};
`

const Span = styled.span`
    margin-left: 0.7em;
    padding: 0 0.3em;
    background-color: ${colors.blue};
    color: ${colors.white};
    font-weight: bold;
`