import React from 'react';
import styled from 'styled-components';
import { devices, colors, borderRadius, fontStyles } from '../globalStyles';
import CopyComponent from './CopyComponent';
import Collapse from './Collapse'
import { mapTagNameDisplayName } from '../Map';


function ItemDetails(props) {
  const { name, liquid, index, tags, custom, image } = props;
  const isSecondChild = index%2 === 0;

  return (
    <Container childNr={!isSecondChild}>
      <ItemTitle>{name}</ItemTitle>
      <ImageWrapper>
        <ItemImage src={image}/>
      </ImageWrapper>
      <ItemTagsContainer>
        {tags.map((tag, i) => (
          <ItemTag key={i}>{mapTagNameDisplayName.get(tag)}</ItemTag>
        ))}
      </ItemTagsContainer>
      <ItemInfoContainer>
        <CopyComponent text={liquid} />
        <Code>
          {liquid}
        </Code>
      </ItemInfoContainer>  
      <Collapse content={custom} title={name} />
    </Container>
  );
}

export default ItemDetails;

const Container = styled.div`
  margin: 0 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-content: flex-end;
  max-width: 100%;

  @media ${devices.mobileL} {
    padding: 0;
  }
`

const ItemTitle = styled.p`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  font-family: ${fontStyles.headline};
  text-align: center;
  margin-block: 0;
  margin-bottom: 10px;
`
const ItemImage = styled.img`
  width: 65%;
  align-self: center;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`

const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius};
  background-color: ${colors.grey};
  padding: 10px;
  margin: 10px 0 20px;
`

const Code = styled.code`
  font-size: 14px;
  white-space: break-spaces;
  display: block;
  max-height: 100px !important;
  overflow: auto;
`

const ItemTagsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
`

const ItemTag = styled.div`
  padding: 0.3em 0.6em;
  background: ${colors.blue};
  border-radius: ${borderRadius};
  margin: 0 10px 10px 0;
  font-size: 12px;
  font-weight: bold;
  font-family: ${fontStyles.body};
  text-transform: uppercase;
  color: ${colors.white};
  &:last-child {
    margin-right: 0px;
  }
`
