import { useParams } from "react-router-dom";
import App from '../App';
import { getClientName } from '../Map';

const GetClientId = () => {
    let { clientId } = useParams();
    return getClientName.get(clientId) || null;
}

const Root = () => {
    const clientId  = GetClientId();
    
    if (!clientId) window.location.href = "https://copypastedesigns.com/"
    return (
        <App clientId={clientId} />
    );
};
export default Root;