import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { colors, devices, borderRadius } from '../../globalStyles'

const Dropdown = ({header, renderContent}) => {
    const [isOpen, setIsOpen] = useState(false);

    /* Close dropdown when clicking outside */
    const ref = useRef(null);
    useEffect(() => {
        document.addEventListener("click", (e) => {ref.current && !ref.current.contains(e.target) && setIsOpen(false)}, true);
    }, []);

    return (
        <DropdownWrapper ref={ref}>
            <DropdownHeader isOpen={isOpen} onClick={() => setIsOpen(isOpen => !isOpen)}>
                {header}
                <Icon isOpen={isOpen} viewBox="6 0 12 24">
                    <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                </Icon>
            </DropdownHeader>
            <DropdownContentWrapper>
                {isOpen && 
                    <DropdownContent>
                        {renderContent}
                    </DropdownContent>
                }
            </DropdownContentWrapper>
        </DropdownWrapper>
    );
};

export default Dropdown;

const DropdownWrapper = styled.div`
    cursor: pointer;
    position: relative;
`

const DropdownHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
    color: ${colors.blue};
    padding: 10px;
    border-radius: ${borderRadius};
    border: 3px solid ${colors.blue};
`;

const Icon = styled.svg`
    width: 0.5em;
    fill: ${colors.blue}; 
    will-change: transform;
    transition: transform 500ms;
    transform: rotate(90deg);
    transform: ${props => props.isOpen ? "rotate(270deg)" : "rotate(90deg)" };
`;

const DropdownContentWrapper = styled.div`
    position: absolute;
    z-index: 1;

    @media ${devices.mobileL} {
        width: 100%;
    }
`

const DropdownContent = styled.div``;