import React from 'react';
import styled from 'styled-components'
import ItemDetails from './ItemDetails'
import { devices, fontStyles } from '../globalStyles'

const createSectionObject = (sections) => {
  return sections.map(item => {
    return {
      section: item.value,
      sectionName: item.label,
      itemList: []
    };
  })
}

const mapDataToSections = (data, sections) => {
  return sections.map(item => {
    const itemList = data.filter(i => item.section === i.section);
    return {...item, itemList: itemList }
  })
}

function ItemList(props) {
  const { value, sections } = props;
  const sectionObject = createSectionObject(sections);
  const itemList = mapDataToSections(value, sectionObject);
  
  return (
    <Container>
      {itemList.map((item, i) => item.itemList.length > 0 && (
        <SectionContainer key={i}>
          <Headline>{item.sectionName}</Headline>
            <Section>
              {item.itemList.map((obj, o) => (
                <Item key={o}>
                  <ItemDetails image={obj.url} name={obj.name} liquid={obj.liquid} tags={obj.tag} custom={obj.overwrite_options} index={o}/>
                </Item>
              ))}
          </Section>
        </SectionContainer>
      ))}
    </Container>
  );
}

export default ItemList;

const Container = styled.div`
  width: 100%;
`

const SectionContainer = styled.div`
  width: 100%;
  margin: 2em 0 3em;
`

const Section = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;

  @media ${devices.laptop} {
    justify-content: flex-start;
  }
`
const Item = styled.div`
  width: 33.3%;
  border-radius: 5px;
  display: flex;
  margin: 15px 0;

  @media ${devices.laptop} {
    width: 49%;
    &:nth-child(2) {
      padding-right: 0px;
    }
  }

  @media ${devices.tablet} {
    width: 100%;
    padding: 0;
    max-width: unset;
  }
`
const Headline = styled.h2`
  font-family: ${fontStyles.headline};
  font-size: 2em;
  font-weight: bold;
  margin-block: 0;
  border-bottom: 3px solid black;
`
