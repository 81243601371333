import React, { useState  } from 'react'
import styled from 'styled-components'
import { colors, borderRadius, fontStyles } from '../globalStyles'
import CopyComponent from './CopyComponent'

const Collapse = ({content, title}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper hideElement={!content}>
            <Button isOpen={isOpen} onClick={() => setIsOpen(isOpen => !isOpen)}>
                Overwrite options
                <Icon isOpen={isOpen} viewBox="6 0 12 24">
                    <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                </Icon>
            </Button>
        <CollapseContainer isOpen={isOpen}>
        <CopyComponent text={content} />
            {content}
        </CollapseContainer>
        </Wrapper>
    );
};

export default Collapse;

const Wrapper = styled.div`
    width: 100%;
    visibility: ${props => props.hideElement && "hidden"};
`

const Button = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: ${colors.grey};
    border-radius: ${props => props.isOpen ? "6px 6px 0px 0" : borderRadius};
    color: ${colors.black};
    padding: 10px;
    text-align: left;
    box-shadow: none;
    border: none;
    font-family: ${fontStyles.body};
    font-size: 0.9em;
`;

const Icon = styled.svg`
    width: 0.5em;
    fill: ${colors.black}; 
    will-change: transform;
    transition: transform 500ms;
    transform: rotate(90deg);
    transform: ${props => props.isOpen ? "rotate(270deg)" : "rotate(90deg)" };
`;

const CollapseContainer = styled.div`
    display: ${props => props.isOpen ? "block" : "none"};
    padding: 0 10px 10px 10px;
    white-space: break-spaces;
    background-color: ${colors.grey};
    border-radius: ${borderRadius};
    border-radius: ${props => props.isOpen ? "0 0 6px 6px" : borderRadius};
    color: ${colors.black};
    font-size: 0.9em;
`