import React from 'react'
import styled from 'styled-components'

const Checkbox = ({checked, labelText, onCheck, item}) => {
    return (
        <Label>
            <Input checked={checked} onChange={() => onCheck(!checked, item)} />
            <LabelText>{labelText}</LabelText>
        </Label>
    );
};

export default Checkbox;

const Input = styled.input.attrs({ type: "checkbox" })``;
const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`
const LabelText = styled.span``;