const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

export const font = "monospace";

export const fontStyles = {
  headline: `Montserrat,Abril Fatface,Times New Roman,Times,serif;`,
  body: `Nunito Sans,Helvetica,Arial,sans-serif;`,
}

export const colors = {
  blue: `#44536A`,
  white: `#ffffff`,
  black: `#000000`,
  grey: `#E0E3E6`,
  darkgrey: `#C1C6CD`,
}

export const borderRadius = "6px";
