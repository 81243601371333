import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ItemList from './components/ItemList'
import MultiSelectComponent from './components/baseComponents/MultiSelect'
import _ from 'lodash'
import useGoogleSheets from 'use-google-sheets';
import { devices, colors, fontStyles } from './globalStyles'
import { getSectionArray, getTagArray } from './handlers/filterHandler'
import { textObject } from './handlers/textHandler'


const initialFilterState = { tagFilter: [], sectionFilter: [] };

const App = ( {clientId} ) => {
  const [id] = useState(clientId);
  const [filter, setFilter] = useState(initialFilterState);
  const [items, setItems] = useState([]);
  const [sections, setSections] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setSections(getSectionArray(id));
    setTags(getTagArray(id));
}, [id]);

  const getFilteredItems = () => {
    const activeTagFilterArray = getActiveTagFilters();
    const activeSectionFilterArray = getActiveSectionFilters();

    // to-do: rewrite asap!!
    if (activeTagFilterArray.length === 0 && activeSectionFilterArray.length === 0) {
      return items;
    } else if(activeTagFilterArray.length === 0) {
      return items.filter(item => _.includes(activeSectionFilterArray, item.section))
    } else if (activeSectionFilterArray.length === 0) {
      return items.filter(item =>
        activeTagFilterArray.every(elem => _.includes(item.tag, elem))
     )
    } else {
      return items.filter(item =>
        activeTagFilterArray.every(elem => _.includes(item.tag, elem)) && _.includes(activeSectionFilterArray, item.section)
     )
    }
  }

  const getActiveTagFilters = () => {
    return filter.tagFilter.map(item => item.value.toString());
  }

  const getActiveSectionFilters = () => {
    return filter.sectionFilter.map(item => item.value.toString());
  }

  const applyTagStructure = (data) => {
    return data.map(item => {
      const toArray = item.tag ? item.tag.replace(/\s/g,'').split(',') : [];
      return {...item, tag: toArray };
    })
  }

  const renderFeedbackView = (copy) => {
    return (
      <FeedbackWrapper>
        <FeedbackText>{copy}</FeedbackText>
      </FeedbackWrapper>
    );
  };

  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id }],
  })

  if (loading) {
    return renderFeedbackView(textObject.onLoadingCopy);
  }

  if (error) {
    return renderFeedbackView(textObject.onErrorCopy);
  }

  if (data && !items.length) {
    const updatedData = applyTagStructure(data[0].data);
    setItems(updatedData);
  }

  const resetAllFilters = () => {
    setFilter({ tagFilter: [], sectionFilter: [] });
  }

  const updateTagsFilter = (tagFilter) => {
    setFilter({...filter, tagFilter})
  };

  const updateSelectionFilter = (sectionFilter) => {
    setFilter({...filter, sectionFilter})
  };

  const visibleItems = getFilteredItems();
  const { tagFilter, sectionFilter } = filter;
  
  return (
    <Container>
      <HeaderContainer>
        <HeaderWrapper>
          <TextWrapper>
            <Title>{textObject.headerTitleCopy}</Title>
            <Link href="https://vine-ferryboat-50f.notion.site/How-to-Building-Blocks-f0840b13c12842fb8edcff0fd3819fa9?pvs=4" target="_blank">{textObject.headerBodyCopy}</Link>
          </TextWrapper>
          <FilterContainer>
          <FilterWrapper>
             <MultiSelectComponent selectedItems={sectionFilter} onUpdate={updateSelectionFilter} options={sections} title={textObject.sectionFilterTitle}/>
              <MultiSelectComponent selectedItems={tagFilter} onUpdate={updateTagsFilter} options={tags} title={textObject.tagFilterTitle}/>
            </FilterWrapper>
            <Button onClick={() => resetAllFilters()}>{textObject.clearCopy}</Button>
          </FilterContainer>
        </HeaderWrapper>
      </HeaderContainer>
      { visibleItems.length > 0 ? <ItemList value={visibleItems} sections={sections} /> : renderFeedbackView(textObject.emptyListCopy) }
    </Container>
  );
};

export default App;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
`

const HeaderWrapper = styled.div``

const FilterContainer = styled.div`
  display: flex;
  @media ${devices.mobileL} {
    flex-direction: column;
  }
`

const TextWrapper = styled.div`
  margin-bottom: 2em;
  text-align: center;

  @media ${devices.mobileL} {
    align-self: flex-start;
    text-align: left;
  }
`

const Title = styled.h1`
  margin: 0;
  margin-bottom: 10px;
  line-height: 1.1;
  font-size: 3em;
  font-weight: bold;
  font-family: ${fontStyles.headline};
  
  @media ${devices.mobileL} {
    font-size: 2.5em;
  }
`

const Link = styled.a`
  margin: 0;
  padding-top: 5px;
  line-height: 1.1;
  font-size: 1.1em;
  font-weight: 400;
  font-family: ${fontStyles.body};
  color: ${colors.black};
`

const Container = styled.div`
  margin: 3.5em 2em;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`
const Button = styled.button`
  background: transparent;
  color: ${colors.blue};
  font-family: ${fontStyles.body};
  margin: 0 0 0 1em;
  font-size: 1.2em;
  border: none;
  text-decoration: underline;
  cursor: pointer;

  @media ${devices.mobileL} {
    margin: 10px 0;
  }
`
const FilterWrapper = styled.div`
  display: flex;
  @media ${devices.mobileL} {
    flex-wrap: wrap;
  }
`

const FeedbackWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
`

const FeedbackText = styled.h2`
  font-family: ${fontStyles.body};
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  width: 70%;
`