import { useState } from 'react';
import styled from 'styled-components'
import logo from './copy-icon.png';

export default function CopyComponent(props) {
    const [isVisible, setVisibility] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        setVisibility((isVisible) => true);
        setTimeout(() => {setVisibility((isVisible) => false)}, 3000);
    }
    return (
        <Container>
            <Span show={isVisible}>Copied to clipboard!</Span>
            <CopyIcon src={logo} onClick={() => {copyToClipboard(props.text)}}/>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Span = styled.span`
    display: ${props => props.show ? "block" : "none"};
    font-size: 0.7em;    
`;

const CopyIcon = styled.img`
  width: 30px;
`
